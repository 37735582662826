import clsx from "clsx";
import { useRouter } from "next/router";
import React from "react";
import ReactPaginate from "react-paginate";
import zeroFill from "../lib/zeroFill";

const Pagination = ({
	pageCount = 1,
	activePage = 1,
	basePath = "/about/news",
}) => {
	const router = useRouter();

	const handlePageClick = (page) => {
		const targetPage = page.selected + 1;

		if (targetPage === 1) {
			router.push(basePath);
		}
		
		router.push(`${basePath}?page=${targetPage}`);
	};

	return (
		<div className={clsx("Pagination", "px-7.5 lg:px-15 text-center")}>
			<ReactPaginate
				previousLabel="Previous"
				previousClassName="hidden"
				nextLabel="Next"
				nextClassName="hidden"
				forcePage={activePage - 1}
				breakLabel="..."
				breakClassName={clsx(
					"block w-15 h-15 leading-[60px] rounded-full text-center border mr-4 opacity-25"
				)}
				pageCount={pageCount}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				onPageChange={handlePageClick}
				containerClassName="text-center w-full"
				pageClassName={clsx("inline-block mr-4 last-of-type:mr-0")}
				previousLinkClassName="px-8"
				nextLinkClassName="block px-8"
				pageLinkClassName="block w-15 h-15 leading-[60px] rounded-full text-center border transition-opacity opacity-25 hover:opacity-100"
				activeClassName=""
				activeLinkClassName="!opacity-100"
				hrefBuilder={(page) => {
					return `${basePath}?page=${page}`;
				}}
				pageLabelBuilder={(page) => {
					return `${zeroFill(page, 2)}.`;
				}}
			/>
		</div>
	);
};

export default Pagination;
