import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";

interface IProps {
	className?: string;
	src?: string;
	autoplay?: boolean;
	controls?: boolean;
  iconColor?: string;
  playsInline?: boolean;
  playMuted?: boolean;
  loop?: boolean;
	setControlContextData?: (data: any) => void;
}

const EmbeddedVideoPlasmic = ({
  className,
  src,
  autoplay,
  controls,
  iconColor = "#6863F2",
  playsInline,
  playMuted,
  loop,
  setControlContextData,
}: IProps) => {

	const [isPlaying, setIsPlaying] = useState(autoplay);
	const [aspectRatio, setAspectRatio] = useState(1.777);

	const ref = useRef(null);

	const getAspect = (asset) => {
		const { width, height } = asset;
		const aspect = width / height;
		return aspect;
	};

	const handleReady = () => {
		const player = ref.current.getInternalPlayer();
		const video = player.data.media.assets[0];
		setAspectRatio(video);
	};

	const handlePlay = () => {
		setIsPlaying(true);
		const player = ref.current.getInternalPlayer();
		const video = player.data.media.assets[0];
		const aspect = getAspect(video);
		setAspectRatio(aspect);
	};

	const [isSSR, setIsSSR] = useState(true);

	useEffect(() => {
		setIsSSR(false);
	}, []);

	return (
		<Box
			className={`video-player ${className}`}
			pos="relative"
			sx={{
				aspectRatio: `${aspectRatio}`,
			}}
			width="100%"
		>
			{!!src && !isSSR && (
				<ReactPlayer
					key={iconColor}
					ref={ref}
					url={src}
					controls={controls}
					playing={isPlaying}
					onReady={() => {
						handleReady();
					}}
					onPlay={() => {
						handlePlay();
					}}
					onPause={() => {
						setIsPlaying(false);
					}}
					muted={playMuted}
          playsinline={playsInline}
          loop={loop}
					width="100%"
					height="100%"
					config={{
						wistia: {
							options: {
								playerColor: iconColor,
								controlsVisibleOnLoad: false,
								copyLinkAndThumbnailEnabled: false,
								//playButton: videoStyle !== "autoplayInline",
								wmode: "transparent",
								fitStrategy: "contain",
								chromeless: false,
								videoFoam: true,
								fullscreenButton: true,
							},
						},
					}}
				/>
			)}
		</Box>
	);
};

export default EmbeddedVideoPlasmic;
