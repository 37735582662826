import {cloneElement, ReactElement, ReactNode,  Children } from "react";

export interface ClickProps {
	children?: ReactNode;
	className?: string;
}

const ClickEvent = ({
	children,
	eventDescription,
	...props
}: {
	children?: ReactNode;
	eventDescription?: string;
}) => {

    const handleClick = () => {
        window.dataLayer?.push({
            event: "event",
            eventProps: {
                category: "Button Click",
                action: eventDescription,
            },
        });
    }

	const filteredProps = Object.fromEntries(
		Object.entries(props).filter(
			([key]) => !key.startsWith("data-plasmic") && key !== "className"
		)
	);
	
    return Children.map(children, (child) =>
    // if child is null code will break, this may happen when child visibility is toggled in studio
    !!child && cloneElement(child as ReactElement, {
      ...filteredProps, // forward extra props for composability
      onClick: () => handleClick()
    })
  );
}

export default ClickEvent
