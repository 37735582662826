import { A11y, Autoplay, Controller, Grid, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { clsx } from 'clsx';
import { ReactNode, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ArrowLeft, ArrowRight, ChevronLeft, ChevronRight } from "../../apps/abnormal/components/Icons";

import { Box, Button, Stack } from "@chakra-ui/react";
import { PlasmicCanvasContext } from "@plasmicapp/loader-nextjs";

interface IProps {
  className?: string;
  children?: ReactNode | any;
  previewSlide?: number;
  sliderId?: string;
  slidesPerView?: number;
  tabletSlidesPerView?: number;
  mobileSlidesPerView?: number;
  slidesToScroll?: number;
  grid?: boolean;
  rows?: number;
  loop?: boolean;
  touch?: boolean;
  autoplay?: boolean;
  autoplaySpeed?: number;
  autoplayDelay?: number;
  autoplayPause?: boolean;
  ticker?: boolean;
  slideSpacing?: string;
  spacing?: number;
  centeredSlides?: boolean;
  showDots?: boolean;
  dotsColor?: string;
  dotsSpacing?: string;
  dotsClasses?: string;
  arrowDisplay?: string;
  animateButtons?: boolean;
  buttonsWithPagination?: boolean;
  overlayButtons?: boolean;
  buttonBackgroundColor?: string;
  buttonXOffset?: string;
  buttonOffset?: string;
  wrapperClasses?: string;
  controlledSlider?: typeof Swiper | typeof Swiper[];
  onActiveSlideChange?: (activeIndex: number) => void;
}

const Slider = ({
  className,
  children,
  previewSlide,
  sliderId,
  slidesPerView,
  mobileSlidesPerView,
  tabletSlidesPerView,
  slidesToScroll,
  grid = false,
  rows = 1,
  loop,
  touch,
  autoplay = false,
  autoplayDelay = 6000,
  autoplayPause = false,
  ticker,
  autoplaySpeed,
  slideSpacing = "justify-normal",
  spacing,
  centeredSlides,
  showDots,
  dotsColor = "default",
  dotsSpacing = "30px",
  dotsClasses = "",
  arrowDisplay,
  animateButtons,
  buttonsWithPagination = false,
  overlayButtons,
  buttonBackgroundColor = "#FFFFFF",
  buttonXOffset,
  buttonOffset,
  wrapperClasses,
  controlledSlider,
  onActiveSlideChange
}: IProps) => {
  const [swiper, setSwiper] = useState(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [id, setId] = useState(() => { return !!sliderId ? sliderId : uuidv4(); });
  //const [offset, setOffset] = useState(null);

  const showPagination = showDots && children.length > 1;
  const showPaginationArrows = showDots && buttonsWithPagination;

  const handleSlideChange = (swiper) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
    !!onActiveSlideChange && onActiveSlideChange(swiper.activeIndex);
  };

  const inEditor = useContext(PlasmicCanvasContext);

  useEffect(() => {
    if (inEditor && swiper) {
      if (previewSlide > currentIndex) {
        swiper.slideNext();
        setCurrentIndex(currentIndex + 1);
      }
      if (previewSlide < currentIndex) {
        swiper.slidePrev();
        setCurrentIndex(currentIndex - 1);
      }
    }
  }, [previewSlide]);

  const NavButton = ({ direction = "next" }) => {
    const handleClick = () => {
      if (direction === "next") swiper.slideNext();
      if (direction === "prev") swiper.slidePrev();
    };

    if (showPaginationArrows) {
      return (
        <Button
          width="12"
          height="12"
          variant="unstyled"
          color={dotsColor === "light" ? "white" : "black"}
          padding="0"
          zIndex="30"
          transition="all"
          opacity={1}
          pointerEvents={null}
          transitionProperty="all"
          transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
          transitionDuration="150ms"
          _hover={{
            transitionProperty: "all",
            transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
            transitionDuration: "150ms",
            transform:
              (direction === "next"
                ? "translateX(0.25rem)"
                : "translateX(-0.25rem)"),
          }}
          _focus={{
            boxShadow: "none",
          }}
          onClick={handleClick}
        >
          {direction === "next" ? <ChevronRight className="w-12 h-12" /> : <ChevronLeft className="w-12 h-12" />}
        </Button>
      );
    }

    const hide =
      !loop && ((direction === "prev" && isBeginning) || (direction === "next" && isEnd));

    return (
      <Button
        width="12"
        height="12"
        backgroundColor={buttonBackgroundColor}
        rounded="full"
        position="absolute"
        top={buttonOffset ? buttonOffset : "50%"}
        marginTop="-24px"
        padding="0"
        zIndex="30"
        transition="all"
        display="flex"
        alignItems="center"
        justifyContent="center"
        right={direction === "next" ? (overlayButtons ? (buttonXOffset? buttonXOffset : "1.875rem") : "0") : null}
        left={direction === "prev" ? (overlayButtons ? (buttonXOffset ? buttonXOffset : "1.875rem") : "0") : null}
        opacity={hide ? 0 : 1}
        pointerEvents={hide ? "none" : null}
        transitionProperty="all"
        transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
        transitionDuration="150ms"
        _hover={{
          backgroundColor: "#FFFFFF",
          transitionProperty: "all",
          transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
          transitionDuration: "150ms",
          transform:
            animateButtons &&
            (direction === "next"
              ? "translateX(0.5rem)"
              : "translateX(-0.5rem)"),
        }}
        _active={{
          backgroundColor: "#FFFFFF",
        }}
        onClick={handleClick}
      >
        {direction === "next" ? <ArrowRight /> : <ArrowLeft />}
      </Button>
    );
  };

  return (
    <Box position="relative" width="full" className={className}>
      <Box
        position="relative"
        px={overlayButtons || showPaginationArrows || arrowDisplay === "hide" ? "0" : "60px"}
        userSelect="none"
        className="swiper-outside"
      >
        <Swiper
          key={sliderId}
          id={sliderId}
          modules={[A11y, Autoplay, Controller, Grid, Pagination]}
          // controller={controller && !!controlledSlider && {
          //   control: controlledSlider,
          // }}
          grid={grid && { fill: "row", rows: rows }}
          pagination={showPagination && {
            clickable: true,
            el: `.swiper-pagination`,
            bulletClass: clsx("swiper-pagination-bullet", {
              "swiper-pagination-light": dotsColor === "light",
            }),
          }}
          centeredSlides={centeredSlides}
          a11y={{
            prevSlideMessage: "Previous slide",
            nextSlideMessage: "Next slide",
          }}
          style={{
            overflowX: "hidden",
            overflowY: "visible",
            paddingBottom: showPagination ? dotsSpacing : null,
            userSelect: "text",
            width: "100%",
          }}
          wrapperClass={clsx("swiper-wrapper", wrapperClasses, slideSpacing, {
            "swiper-ticker": ticker,
          })}
          allowTouchMove={ticker ? false : touch}
          grabCursor={ticker ? false : touch}
          loop={loop}
          autoplay={autoplay && {
            delay: ticker ? 0 : autoplayDelay,
            disableOnInteraction: false,
            pauseOnMouseEnter: autoplayPause,
          }}
          loopAddBlankSlides={true}
          loopPreventsSliding={loop && !ticker}
          speed={autoplay && autoplaySpeed || 300}
          spaceBetween={spacing}
          slidesPerView={mobileSlidesPerView ? mobileSlidesPerView : 'auto'}
          slidesPerGroup={slidesToScroll ? slidesToScroll : 1}
          onSwiper={(swiper) => {
            setSwiper(swiper);
          }}
          onSlideChange={handleSlideChange}
          breakpoints={{
            641: {
              slidesPerView: tabletSlidesPerView ? tabletSlidesPerView : 'auto',
              slidesPerGroup: slidesToScroll ? slidesToScroll : 1,
            },
            769: {
              slidesPerView: tabletSlidesPerView ? tabletSlidesPerView : 'auto',
              slidesPerGroup: slidesToScroll ? slidesToScroll : 1,
            },
            1025: {
              slidesPerView: slidesPerView ? slidesPerView : 'auto',
              slidesPerGroup: slidesToScroll ? slidesToScroll : 1,
            },
          }}
        >
          {children.length > 1
            ? children.map((child, index) => {
                return (
                  <SwiperSlide
                    key={`${child.props.className}-${index}`}
                    style={{ height: "auto", width: "auto", marginRight: spacing}}
                  >
                    {child}
                  </SwiperSlide>
                );
              })
            : (
              <SwiperSlide
                key={children.className}
                style={{ height: "auto", width: "auto"}}
              >
                {children}
              </SwiperSlide>
            )}
          {showPagination && (
            <Stack
              className={clsx("swiper-pagination-wrapper",dotsClasses)}
              direction="row"
              justify="center"
              align="center"
              gap="2"
              w="full"
            >
              {showPaginationArrows && (
                <NavButton direction={"prev"} />
              )}
              <Stack
                className="swiper-pagination swiper-pagination-horizontal swiper-pagination-bullets"
                direction="row"
                justify="center"
                align="center"
                gap="0"
                py="2"
              ></Stack>
              {showPaginationArrows && (
                <NavButton direction={"next"} />
              )}
            </Stack>
          )}
        </Swiper>
      </Box>
      { arrowDisplay !== "hide" && !showPaginationArrows &&
        (arrowDisplay === "show"
          || ((slidesPerView !== 0) && (children.length > slidesPerView))
        ) && (
        <Box
          position="absolute"
          top="0"
          width="full"
          height="full"
          className="controls"
        >
          <NavButton direction={"prev"} />
          <NavButton direction={"next"} />
        </Box>
      )}
    </Box>
  );
};

export default Slider;
