import { ReactNode } from "react";
import { Box } from "@chakra-ui/react";

interface IProps {
	className?: string;
	children?: ReactNode | any;
}

const SliderItem = ({ className, children }: IProps) => {
	return (
		<Box height="100%" className={className}>
			{children}
		</Box>
	);
};

export default SliderItem;
