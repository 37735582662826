import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

interface IProps {
	className?: string;
	heading?: ReactNode | any;
	content?: ReactNode | any;
}

const AccordionItem = ({heading, content}: IProps) => {
  // This component is utilized by Plasmic to register AccordionItem for use in projects
  // For front-end implementation, see Accordion.tsx
	return (
		<Box>
			<Box>{heading}</Box>
			<Box>{content}</Box>
		</Box>
	);
};
export default AccordionItem;
