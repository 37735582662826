import { Box, VStack } from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import Slider from "./Slider";

interface IProps {
  className?: string;
  children?: ReactNode | any;
  // onFirstSliderInit?: (slider: any, classes?: string) => void;
  // onSecondSliderInit?: (slider: any, classes?: string) => void;
}

const ControlledSliders = ({
  className,
  children,
  // onFirstSliderInit,
  // onSecondSliderInit,
}: IProps) => {
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);

  const handleSwiperInit = (swiper: any, classes: string) => {
    if (classes.includes("product-stages")) {
      // setFirstSwiper(swiper);
    } else {
      // setSecondSwiper(swiper);
    }
  }

  console.log("Controlled Children", children);
  
  // const renderSlider = (children: any, props?: Object) => {
  //   return <Box>{children}</Box>;
  //   // const slider = (children.type?.length > 1) ? children[0] : children;
  //   // return (
  //   //   <Slider
  //   //     {...slider.props}
  //   //     {...props}
  //   //   >
  //   //     {slider.children}
  //   //   </Slider>
  //   // );
  // }

  return (
    <VStack className={className} width="full">
      {/* {renderSlider(firstSlider, {
        wrapperClasses: `swiper-controller-first ${firstSlider.props.wrapperClasses || ""}`,
        controlledSlider: secondSwiper,
        onSliderInit: handleSwiperInit,
      })}
      {renderSlider(secondSlider, {
        wrapperClasses: `swiper-controller-second ${secondSlider.props.wrapperClasses || ""}`,
        controlledSlider: firstSwiper,
        onSliderInit: handleSwiperInit,
      })} */}
    </VStack>
  );
}

export default ControlledSliders
