import {
  AccordionButton,
  Accordion as AccordionComponent,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import { Prose } from "@nikolovlazar/chakra-ui-prose";
import { PlasmicCanvasContext } from "@plasmicapp/loader-nextjs";
import { ReactNode, useContext, useState } from "react";
import { ChevronDown, ChevronUp } from "../../apps/abnormal/components/Icons";

// Helper function to build array of ids off the indices
const getExpandedIds = (expandedIndex: number | Array<number>, accordionItems: Array<any>) => {
  const expandedIds = [];
  if (expandedIndex?.constructor === Array) {
    expandedIndex.map((item, index) => {
      expandedIds.push(accordionItems[item]?.id || `accordion-${index}`);
    });
  } else if (expandedIndex !== -1 && expandedIndex !== null) {
    expandedIds.push(accordionItems[expandedIndex]?.id || `accordion-${expandedIndex}`);
  }
  return expandedIds;
}

interface IProps {
  children?: ReactNode | any;
  className?: string;
  expandAll?: boolean;
  allowMultiple?: boolean;
  allowToggle?: boolean;
  reduceMotion?: boolean;
  style?: string;
  spacing?: number;
  onExpandedChange?: (expandedItems: Array<any>) => void;
}

const Accordion = ({
  className,
  children,
  expandAll,
  allowMultiple,
  allowToggle,
  reduceMotion,
  style = "default",
  spacing = 0,
  onExpandedChange,
}: IProps) => { 
  if (typeof children.length === "undefined") return children;

  const inEditor = useContext(PlasmicCanvasContext);

  // Build accordion items from children, while also initializing the default indices array
  const defaultIndex = [];
  const accordionItems = children.map((item, index) => {
    // If the item is expanded, add it to the defaultIndex array
    if (item.props.expanded) {
      defaultIndex.push(index);
    }
    return {
      id: item.props.id,
      heading: item.props.heading,
      content: item.props.content,
    };
  });
  // Initialize expandedItems with information from default expansion settings
  const [expandedItems, setExpandedItems] = useState(() => {
    !!onExpandedChange && onExpandedChange(getExpandedIds(defaultIndex, accordionItems));
    if (expandAll || inEditor && allowMultiple) {
      // Expand all accordion items
      return [...Array(accordionItems.length).keys()];
    } else {
      // Return array if allowMultiple is true, else return the first default item (or fallback to empty array)
      return allowMultiple ? defaultIndex
        : defaultIndex.length ? defaultIndex[0]
          : [];
    }
  });

  // Event handler for when an accordion is expanded/collapsed
  const handleChange = (expandedIndex) => {
    setExpandedItems(expandedIndex);
    !!onExpandedChange && onExpandedChange(getExpandedIds(expandedIndex, accordionItems));
  };

  return (
    <AccordionComponent
      defaultIndex={expandedItems}
      allowMultiple={allowMultiple}
      allowToggle={allowToggle}
      reduceMotion={reduceMotion}
      className={className}
      variant={style}
      onChange={handleChange}
    >
      {accordionItems.map((item, index) => {
        const isExpanded = expandedItems?.constructor === Array ? expandedItems.includes(index) : expandedItems === index;
        const isExpandedCard = isExpanded && (style === "card");
        return (
          <AccordionItem
            key={index}
            id={item.id}
            mb={isExpanded && `${spacing}px`}
            backgroundColor={isExpandedCard ? "purple-gray-02" : "transparent"}
            border={isExpandedCard ? "none!important" : null}
            boxShadow={isExpandedCard ? "0px 0px 30px 0px rgba(0, 0, 0, 0.15)" : null}
            py={isExpandedCard ? 7 : 0 }
          >
            {({ isExpanded }) => (
              <>
                <AccordionButton textAlign="left">
                  {item.heading}
                  {(isExpanded ? <ChevronUp /> : <ChevronDown />)}
                </AccordionButton>
                <AccordionPanel>
                  <Prose>{item.content}</Prose>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        );
      })}
    </AccordionComponent>
  );
};

export default Accordion;
