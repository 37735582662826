import React, { ReactNode } from "react";
import { useState, useEffect } from "react";
import { usePlasmicContext } from "../../apps/abnormal/context/plasmic";
import { Box } from "@chakra-ui/react";
import { DataProvider } from "@plasmicapp/loader-nextjs";

import craft from "../../apps/abnormal/pages/api/craft";
import QueryPage from "@queries/QueryPage.graphql";

export interface IProps {
	children?: ReactNode;
	className?: string;
	pageRoute?: any;
}

const PagePropsProvider = ({ className, children, pageRoute }: IProps) => {
	const plasmicContext = usePlasmicContext();
	const [pageProps, setPageProps] = useState(null);
	const fetchCraftData = async (pageRoute) => {
		const result = await craft(QueryPage, {
			uri: pageRoute.slice(1),
			status: ["live", "disabled"],
		});
		if (!result["pageEntry"] && result["landingPageEntry"]) {
			result["pageEntry"] = result["landingPageEntry"];
		}
		return result;
	};

	//runtime in studio editor and previewing
	useEffect(() => {
		if (!plasmicContext.pageProps && pageRoute) {
			fetchCraftData(pageRoute).then((res) => {
				setPageProps(res);
			});
		}
	}, [pageRoute]);

	//during build time and local dev
	useEffect(() => {
		if (plasmicContext.pageProps) {
			setPageProps(plasmicContext.pageProps);
		}
	});

	return (
		<Box className={className} width="100%">
			<DataProvider name="pageProps" data={pageProps}>
				{children}
			</DataProvider>
		</Box>
	);
};

export default PagePropsProvider;
