import { Text } from '@chakra-ui/react';
import { useField, useForm } from 'react-final-form';

const Error = ({ name, neverbounceStatus = null, disableMutator = false }) => {
  const form = useForm();
  const field = useField(name, { subscription: { touched: true, error: true } })

  if (neverbounceStatus && !disableMutator) {
    form.mutators.setFieldData(name, { neverbounceStatus: neverbounceStatus });
  }

  return field.meta.touched && field.meta.error ? (
    <Text p="7px 16px" lineHeight="1.5" color="#CB0A0A" fontSize="12px" borderTop="1px solid red">
      {field.meta.error}
    </Text>
  ) : null
}

export default Error;
