import { Box, chakra, shouldForwardProp } from '@chakra-ui/react';
import { isValidMotionProp, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const MotionBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

const WrapperComponent = ({
  children,
  className,
  disabled,
  animations,
  layoutAnimations = false,
  triggerOnce = true,
  ...props
}) => {
  // const containerRef = useRef(null);
  const { ref, inView } = useInView({
    triggerOnce: triggerOnce,
    threshold: 0.5,
  });

  // useEffect(() => {
  //   // Find all child elements with the 'counter' class name
  //   const counters = containerRef.current ? containerRef.current.querySelectorAll('.counter') : null;

  //   // If there are no counters, return
  //   if (!counters) return;

  //   // If there are counters, animate them

  //   if(counters) {
  //     counters.forEach(counter => {
  //       const endValue = parseInt(counter.textContent, 10);
  //       // Assuming the counter text content is the end value
  //       if (!isNaN(endValue)) {
  //         const duration = 2000; // Duration of animation in milliseconds
  //         let startValue = 0;
  
  //         const frameDuration = 1000 / 60;
  //         const totalFrames = Math.round(duration / frameDuration);
  //         const incrementPerFrame = (endValue - startValue) / totalFrames;
  
  //         let currentFrame = 0;
  //         const counterAnimation = setInterval(() => {
  //           currentFrame++;
  //           startValue += incrementPerFrame;
  //           counter.textContent = Math.round(startValue).toString();
  
  //           if (currentFrame === totalFrames) {
  //             clearInterval(counterAnimation);
  //             counter.textContent = endValue.toString(); // Ensure it ends on the exact end value
  //           }
  //         }, frameDuration);
  //       }
  //     });
  //   }
  // }, [children]); // Dependency array includes children to re-run the effect when they change

  // Can use either <Box as={motion.div} /> or <MotionBox /> to wrap the children.
  // Decided to use this method so we could disable the animation with a simple prop
  return (
    <Box
      as={disabled ? null : motion.div}
      ref={ref}
      className={className}
      initial="initial"
      animate={inView ? "animate" : "initial"} // Dynamically set based on inView
      layout={layoutAnimations}
      variants={animations} // Ensure this is structured correctly
      {...props}
    >
      {children}
    </Box>
  );
};

export default WrapperComponent;


