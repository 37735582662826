import { useEffect } from "react";
import { PLASMIC } from "../../apps/abnormal/plasmic-init";

export interface IProps {
	updateValue?: any;
	value?: string;
}

const PageRoute = ({ updateValue, value }: IProps) => {
	const fetchComponent = async (name) => {
		return await PLASMIC.maybeFetchComponentData(name);
	};

	useEffect(() => {
		//parse url hash to get component name
		const hash = window.location.hash;
		const hashArray = hash.split("&");
		const hashObject: { componentName?: string } = {};
		for (let index = 0; index < hashArray.length; index++) {
			const text = hashArray[index];
			const tempArray = text.split("=");
			const key = tempArray[0];
			const value = tempArray[1];
			hashObject[key] = value;
		}
		const page = hashObject.componentName;
		fetchComponent(page).then((res) => {
			updateValue(res.entryCompMetas[0].path);
		});
	}, []);

	return <div>{value}</div>;
};

export default PageRoute;
