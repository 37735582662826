import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from 'react';

interface IProps {
  showHeading?: boolean;
	countdownHeading?: string;
  endDate?: string;
  textColor?: string;
  dividerColor?: string;
}

const indicatorStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  margin: '0 31px 0 0',
}

const grid = {
	display: 'grid',
	gridTemplateColumns: "1fr auto 1fr auto 1fr",
	alignItems: "center",
}

const header = {
	fontFamily: "TWK Everett",
	fontSize: "24px",
	fontStyle: "normal",
	fontWeight: "400",
	lineHeight: "36px",
}

const pullHeader = {
	fontSize: "52px",
	fontStyle: "normal",
	fontWeight: "400",
	lineHeight: "60px",
}

const bodyRegular = {
	fontFamily: "TWK Everett",
	fontSize: "16px",
	fontStyle: "normal",
	fontWeight: "400",
	lineHeight: "24px",
}

const CountdownPlasmic = ({
  showHeading = true,
  countdownHeading = "Event starts in:",
  endDate,
  textColor = "#0d1418",
  dividerColor = "#0d1418",
}: IProps) => {
  const [selectedDate, setSelectedDate] = useState<string>(endDate || '');
  const [timeLeft, setTimeLeft] = useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const divider = (
    <svg width="1" height="39" viewBox="0 0 1 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.5" y1="2.18557e-08" x2="0.499998" y2="39" stroke={dividerColor} />
    </svg>
  );

  const calculateTimeLeft = () => {
    if (selectedDate) {
      const difference = +new Date(selectedDate) - +new Date();
      if (difference > 0) {
        return {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / (1000 * 60)) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }
    }
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
	};

	useEffect(() => {
		setSelectedDate(endDate)
	 }, [endDate]);
	
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, [selectedDate]);

	return (
		<Flex align="center" color={textColor}>
      {showHeading && (<p style={header}>{countdownHeading}</p>) }
			<Box style={grid}>
				<Box style={indicatorStyle}><span style={pullHeader}>{timeLeft.days}</span> <span>Days</span></Box>
				{divider}
				<Box style={indicatorStyle}><span style={pullHeader}>{timeLeft.hours}</span><span>Hours</span></Box>
				{divider}
				<Box style={indicatorStyle}><span style={pullHeader}>{timeLeft.minutes}</span> <span>Minutes</span></Box>
			</Box>
		</Flex>
  );
};

export default CountdownPlasmic;
