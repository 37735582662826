import { createContext, useContext } from "react";

const AppContext = createContext({ pageProps: null });

export function PlasmicContext({ children, pageProps }) {
	const plasmicCtxData = {
		pageProps,
	};

	return (
		<AppContext.Provider value={plasmicCtxData}>{children}</AppContext.Provider>
	);
}

export function usePlasmicContext() {
	return useContext(AppContext);
}
