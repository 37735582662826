import React from "react";
import { Link } from "react-scroll";
import { Box } from "@chakra-ui/react";

interface IProps {
	children?: ReactNode | any;
	text?: string;
	anchorName?: string;
}

const FloatingNavItem = ({ children, text, anchorName }: IProps) => {
	return (
		<Box
			as={Link}
			display="block"
			activeClass="active"
			to={anchorName}
			spy={true}
			smooth={true}
			offset={-100}
			duration={500}
			href={`#${anchorName}`}
			fontSize="14px"
			p="9px"
			pr="3px"
			borderTop="1px solid #CECEDC"
			_hover={{
				background: "rgba(104,99,242,.1)",
			}}
			borderLeft="4px solid transparent"
		>
			{text}
		</Box>
	);
};

export default FloatingNavItem;
