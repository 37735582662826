const PreviewControl = ({ updateValue, value }) => {
	const increaseValue = () => {
		updateValue(value + 1);
	};
	const decreaseValue = () => {
		updateValue(value - 1);
	};
	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				justifyContent: "space-between",
			}}
		>
			<button
				onClick={() => decreaseValue()}
				style={{
					background: "#F3F3F2",
					padding: "4px 12px",
					borderRadius: "2px",
				}}
			>
				Previous
			</button>
			<button
				onClick={() => increaseValue()}
				style={{
					background: "#F3F3F2",
					padding: "4px 12px",
					borderRadius: "2px",
				}}
			>
				Next
			</button>
		</div>
	);
};

export default PreviewControl;
