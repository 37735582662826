import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea
} from "@chakra-ui/react";
import React, {
  MutableRefObject, useCallback, useContext,
  useEffect,
  useRef, useState
} from "react";
import { Field, useForm } from "react-final-form";

import { FormContext } from "../lib/formContext.ts";

import { Prose } from "@nikolovlazar/chakra-ui-prose";
import { PlasmicCanvasContext } from "@plasmicapp/host";
import parse from "html-react-parser";

import flatpickr from "flatpickr";
import { Instance } from "flatpickr/dist/types/instance";

import Error from "@abnormalComponents/FormError";
import { convertRegion, TO_NAME } from "@abnormalLib/convertRegion";
import formValidators from "@abnormalLib/formValidators";

const FormFields = (props) => {
  const form = useForm();

  const inEditor = useContext(PlasmicCanvasContext);
  const rowSpacing = "16px";
  const slimMode = props.slimMode || false;

  let isDateTime = false;

  const {
    fieldType = "text",
    fieldName = "",
    label = "Field Label",
    required = false,
    options = [],
    layout = "column", // "row" or "column
    defaultValue = "",
    className,
    toggleVisibility,
  } = props;

  const {
    urlParams,
    utmCampaign,
    savedFormValues,
    countryValue,
    formCompleteTransforms,
    showHidden,
    darkBackground,
    inputColorScheme,
    changeState = (e) => null,
    handleChange = (e) => null,
  } = useContext(FormContext);

  const getBackgroundColor = () => {
    switch (inputColorScheme) {
      case "gray":
        return "#f3f3f3";  // Replace with the hex code for gray
      case "default":
      default:
        return "white";  // Default background color
    }
  };

  /* Neverbounce Status Handling */ 
  const [neverbounceStatus, setNeverbounceStatus] = useState(null);
  useEffect(() => {
    const inputElement = document.querySelector('input[name="email"]');

    const handleAttributeChange = () => {
      const newValue = inputElement?.getAttribute('data-zi-neverbounce-status');
      const prevValue = inputElement?.getAttribute('data-nbstatus');
      let updatedValue = null;
      switch (newValue) {
        case "invalid":
        case "disposable":
          updatedValue = "invalid";
          break;
        case "valid":
        case "catchall":
        case "unknown":
          updatedValue = "valid";
          break;
        default:
          updatedValue = null;
          break;
      }
      if (prevValue !== updatedValue) {
        if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug") {          
          console.log("Neverbounce Status", neverbounceStatus);
          console.log("Updated Value", updatedValue);
        }
        // setNeverbounceStatus(updatedValue);
        // inputElement.setAttribute('data-nbstatus', updatedValue);
        // form.mutators.setFieldData("email", { neverbounceStatus: updatedValue });
      }
    };

    // Initial attribute value
    // handleAttributeChange();

    // MutationObserver to watch for attribute changes
    const observer = new MutationObserver(handleAttributeChange);

    if (inputElement) {
      observer.observe(inputElement, {
        attributes: true,
        attributeFilter: ['data-zi-neverbounce-status'],
      });
    }

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  // Check if FormComplete custom transform exists for this field
  if (formCompleteTransforms[fieldName]) {
    switch (fieldName) {
      case 'state':
        // Loop through options and convert state to full name
        options.forEach((option) => {
          option.value = convertRegion(option.value, TO_NAME);
        });
        break;
    }
  }

  /* Hidden Field Handling */
  let hiddenField = false;

  if (fieldName === "state") {
    if (countryValue !== "United States" && !inEditor) {
      return null;
    } else if (fieldName === "state" && inEditor) {
      hiddenField = true;
    }
  } else if (
		toggleVisibility &&
		useContext(FormContext).formValues &&
		useContext(FormContext).formValues[toggleVisibility.fieldName] !==
			toggleVisibility.targetValue
	) {
		hiddenField = true;
  }

  switch (fieldType) {
    case "text":
    case "email":
    case "telephone":
    case "number":
      if (!inEditor && hiddenField) {
        return null;
      }
      return (
        <Field
          name={fieldName}
          width={"100%"}
          key={`field-${props["data-plasmic-canvas-envs"]}`}
          initialValue={
            /* Saved Form Values */
            savedFormValues && !!savedFormValues[fieldName]
              ? savedFormValues[fieldName]
              /* URL Params w/UTM Cookie Fallback (not used for utm_campaign) */
              : urlParams && !!urlParams[fieldName]
              ? urlParams[fieldName]
              /* URL Params w/o Cookie Fallback (utm_campaign only) */
              : fieldName === "utm_campaign" && !!utmCampaign["url"]
              ? utmCampaign["url"]
              /* UTM Cookie (utm_campaign only) */
              : fieldName === "utm_campaign" && !!utmCampaign["cookie"] && !!!defaultValue
              ? utmCampaign["cookie"]
              /* Default Value */
              : defaultValue
          }
          validate={fieldType === "email" ? formValidators : null}
        >
          {({ input, meta }) => (
            <FormControl
              className={className}
              isRequired={required}
              mb={rowSpacing}
              backgroundColor={getBackgroundColor()}
            >
              <Box
                borderColor={meta.error && meta.touched ? "#CB0A0A" : "#fff"}
                borderWidth="1px"
              >
                {!slimMode && !!label && <FormLabel   backgroundColor={getBackgroundColor()}>{parse(label)}</FormLabel>}
                <Input
                  type={fieldType === "telephone" ? "tel" : fieldType}
                  data-nbstatus={ meta.data.neverbounceStatus }
                  {...input}
                  variant={slimMode ? "slim" : "default"}
                  placeholder={
                    slimMode && !!label
                    ? parse(label) 
                    : (hiddenField && inEditor 
                        ? `Hidden by: ${toggleVisibility.fieldName}`
                        : null
                      )
                  }
                  onChange={(e) => {
                    handleChange(e);
                    input.onChange(e);
                  }}
                    backgroundColor={getBackgroundColor()}
                />
           
                {/* <Error name={fieldName} neverbounceStatus={fieldType === "email" ? neverbounceStatus : null} disableMutator={true} /> */}
                <Error name={fieldName} />
              </Box>
            </FormControl>

          )}
        </Field>
      );
    case "select":
      return (
        <Field
          name={fieldName}
          key={`field-${props["data-plasmic-canvas-envs"]}`}
          initialValue={
            hiddenField && inEditor
              ? "hidden"
              : fieldName === "country" && countryValue
              ? countryValue
              : savedFormValues && !!savedFormValues[fieldName]
              ? savedFormValues[fieldName]
              : urlParams && !!urlParams[fieldName]
              ? urlParams[fieldName]
              : defaultValue
          }
        >
          {({ input, meta }) => (
            <FormControl
              className={className}
              isRequired={required}
              mb={rowSpacing}
              backgroundColor={getBackgroundColor()}
            >
              {!!label && (<FormLabel backgroundColor={getBackgroundColor()}>{parse(label)}</FormLabel>)}
              {fieldName === "country" && (
                <Select
                  {...input}
                  onChange={(e) => {
                    changeState(e.target.value);
                    handleChange(e);
                    input.onChange(e);
                  }}
                  backgroundColor={getBackgroundColor()}
                >
                  {options.length &&
                    options.map((option, index) => {
                      return (
                        <option
                          key={`${props["data-plasmic-canvas-envs"]}-${fieldName}-${index}`}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                </Select>
              )}
              {fieldName !== "country" && (
                <Select
                  {...input}
                  onChange={(e) => {
                    handleChange(e);
                    input.onChange(e);
                  }}
                >
                  {hiddenField && inEditor && fieldName === "state" ? (
                    <option value="hidden">Hidden by Country Field</option>
                  ) : hiddenField && inEditor ? (
                    <option value="hidden">
                      Hidden by: {toggleVisibility.fieldName}
                    </option>
                  ) : null}
                  {options.length &&
                    options.map((option, index) => {
                      return (
                        <option
                          key={`${props["data-plasmic-canvas-envs"]}-${fieldName}-${index}`}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                </Select>
              )}
            </FormControl>
          )}
        </Field>
      );
    case "radioButtons":
      return (
        <Field
          name={fieldName}
          key={`field-${props["data-plasmic-canvas-envs"]}`}
          type="radio"
          initialValue={
            savedFormValues && !!savedFormValues[fieldName]
              ? savedFormValues[fieldName]
              : urlParams && !!urlParams[fieldName]
              ? urlParams[fieldName]
              : defaultValue
          }
        >
          {({ input, meta }) => (
            <FormControl
              className={className}
              isRequired={required}
              mb={rowSpacing}
            >
              {!!label && (<FormLabel backgroundColor={getBackgroundColor()}>{parse(label)}</FormLabel>)}
              <RadioGroup
                {...input}
                defaultValue={
                  savedFormValues && !!savedFormValues[fieldName]
                    ? savedFormValues[fieldName]
                    : urlParams && !!urlParams[fieldName]
                    ? urlParams[fieldName]
                    : defaultValue
                }
                variant={darkBackground ? "darkBackground" : "default"}
                colorScheme={darkBackground ? "white" : "purple"}
                onChange={(value) => {
                  const radioEvent = {
                    target: {
                      name: fieldName,
                      value: value,
                      type: "radio",
                      checked: false
                    }
                  };
                  handleChange(radioEvent);
                  input.onChange(radioEvent);
                }}
              >
                <Stack
                  direction={layout}
                  spacing={layout === "row" ? 4 : 2}
                  backgroundColor={!!label && getBackgroundColor()}
                  p={!!label && "8px 16px"}
                >
                  {options.length &&
                    options.map((option, index) => {
                      return (
                        <Radio
                          key={`${props["data-plasmic-canvas-envs"]}-${fieldName}-${index}`}
                          value={option.value}
                        >
                          {option.label}
                        </Radio>
                      );
                    })}
                </Stack>
              </RadioGroup>
            </FormControl>
          )}
        </Field>
      );
    case "checkbox":
      return (
        <Field
          name={fieldName}
          key={`field-${props["data-plasmic-canvas-envs"]}`}
          type="checkbox"
          initialValue={
            savedFormValues && !!savedFormValues[fieldName]
              ? savedFormValues[fieldName]
              : urlParams && !!urlParams[fieldName]
              ? urlParams[fieldName]
              : defaultValue
          }
        >
          {({ input, meta }) => (
            <FormControl
              className={className}
              isRequired={required}
              mb={rowSpacing}
              
            >
              <Checkbox
                name={fieldName}
                defaultChecked={defaultValue[0] === undefined ? false : defaultValue[0].toLowerCase() === "y"}
                {...input}
                variant={darkBackground ? "darkBackground" : "default"}
                colorScheme={darkBackground ? "white" : "purple"}
                spacing="1rem"
                onChange={(e) => {
                  handleChange(e);
                  input.onChange(e);
                }}
              >
                {!!label && (<Prose>{parse(label)}</Prose>)}
              </Checkbox>
            </FormControl>
          )}
        </Field>
      );
    case "textArea":
      return (
        <Field
          name={fieldName}
          key={`field-${props["data-plasmic-canvas-envs"]}`}
          backgroundColor={getBackgroundColor()}
          initialValue={
            savedFormValues && !!savedFormValues[fieldName]
              ? savedFormValues[fieldName]
              : urlParams && !!urlParams[fieldName]
              ? urlParams[fieldName]
              : defaultValue
          }
        >
          {({ input, meta }) => (
            <FormControl
              className={className}
              isRequired={required}
              mb={rowSpacing}
              backgroundColor={getBackgroundColor()}
            >
              <FormLabel backgroundColor={getBackgroundColor()}>{label}</FormLabel>
              <Textarea
                name={fieldName}
                {...input}
                backgroundColor={getBackgroundColor()}
                onChange={(e) => {
                  handleChange(e);
                  input.onChange(e);
                }}
              />
            </FormControl>
          )}
        </Field>
      );
    case "hidden":
      return (
        <>
          {!inEditor && (
            <Field
              key={`field-${props["data-plasmic-canvas-envs"]}`}
              name={fieldName}
              initialValue={
                /* Saved Form Values */
                savedFormValues && !!savedFormValues[fieldName]
                  ? savedFormValues[fieldName]
                  /* URL Params w/UTM Cookie Fallback (not used for utm_campaign) */
                  : urlParams && !!urlParams[fieldName]
                  ? urlParams[fieldName]
                  /* URL Params w/o Cookie Fallback (utm_campaign only) */
                  : fieldName === "utm_campaign" && !!utmCampaign["url"]
                  ? utmCampaign["url"]
                  /* UTM Cookie (utm_campaign only) */
                  : fieldName === "utm_campaign" && !!utmCampaign["cookie"] && !!!defaultValue
                  ? utmCampaign["cookie"]
                  /* Default Value */
                  : defaultValue
              }
            >
              {({ input, meta }) => (
                <FormControl
                  className={className}
                  isRequired={required}
                  {...input}
                  backgroundColor={getBackgroundColor()}
                >
                  <Input
                    name={fieldName}
                    type="hidden"
                    {...input}
                    onChange={(e) => {
                      handleChange(e);
                      input.onChange(e);
                    }}
                    backgroundColor={getBackgroundColor()}
                  />
                </FormControl>
              )}
            </Field>
          )}
          {inEditor && showHidden && <Box>Hidden Field: {fieldName}</Box>}
        </>
      );
    case "htmltext":
      return !!defaultValue && (
        <Box
          className={className}
          mb={rowSpacing}
          key={`field-${props["data-plasmic-canvas-envs"]}`}
        >
          <Prose>{parse(parse(defaultValue))}</Prose>
        </Box>
      );
    default:
      return (
        <div key={`field-${props["data-plasmic-canvas-envs"]}`}>
          {fieldType}
        </div>
      );
    case "dateTime":
    case "datetime":
    case "datetime-local":
      isDateTime = true;
    case "date":
      const datePicker = useRef() as MutableRefObject<Instance>;
      return (
        <Field
          name={fieldName}
          width={"100%"}
          key={`field-${props["data-plasmic-canvas-envs"]}`}
          initialValue={
            savedFormValues && !!savedFormValues[fieldName]
              ? savedFormValues[fieldName]
              : urlParams && !!urlParams[fieldName]
              ? urlParams[fieldName]
              : defaultValue
          }
        >
          {({ input, meta }) => {
            const inputRef = useCallback((node) => {
              if (node !== null) {
                datePicker.current = flatpickr(node, {
                  //flatpickr options, for full list see - https://flatpickr.js.org/options/
                  enableTime: isDateTime,
                  minDate: "today",
                  altInput: true,
                  allowInput: false,
                  altInputClass: `fpAltInput ${getBackgroundColor() === '#f3f3f3' as string ? 'gray-bg' : ''}`,
                  altFormat:
                    // user friendly format to display
                    isDateTime ? "F j, Y at h:i K" : "F j, Y",
                  dateFormat:
                    // format of data that is actually sent
                    //fieldType === "dateTime" ? "Y-m-dTH:i:S" : "Y-m-d",
                    isDateTime ? "Y-m-dTH:i:S" : "Y-m-d",
                  onChange: (selectedDates, dateStr, instance) => {
                    input.onChange(dateStr);
                  },
                });
              }
            }, []);

            return (
              <FormControl
                className={className}
                isRequired={required}
                mb={rowSpacing}
                backgroundColor={getBackgroundColor()}
              >
                <Box
                  borderColor={meta.error && meta.touched ? "#CB0A0A" : "#fff"}
                  borderWidth="1px"
                >
                  {!!label && (<FormLabel backgroundColor={getBackgroundColor()}>{parse(label)}</FormLabel>)}
                  <Input
                    name={input.name}
                    value={input.value}
                    type="date"
                    ref={inputRef}
                    placeholder={
                      hiddenField && inEditor
                        ? `Hidden by: ${toggleVisibility.fieldName}`
                        : null
                    }
                    onChange={(e) => {
                      //console.log(e);
                    }}
                    backgroundColor={inputColorScheme}
                  />
                </Box>
                <Error name={fieldName} />
              </FormControl>
            );
          }}
        </Field>
      );
  }
};

export default FormFields;
