import React from "react";

const AudioPlayer = (
  { audioFileUrl }: { audioFileUrl: string }
) => { 
  return ( 
    <audio controls style={{
      width: "100%",
    }}>
      <source src={audioFileUrl} type="audio/mp3"></source>
    </audio>
  )
}

export default AudioPlayer;