import React from "react";
import { Box } from "@chakra-ui/react";

interface IProps {
	children?: ReactNode | any;
	className: string;
}

const FloatingNav = ({ children, className }: IProps) => {
	return (
		<Box
			pos="sticky !important"
			top={110}
			zIndex={10}
			left={5}
			height={0}
			w="full"
			className={className}
			display={{ base: "none", xl: "block" }}
		>
			<Box
				className="FloatingNav__container"
				maxW="1500px"
				w="full"
				mx="auto"
				style={{ pointerEvents: "none" }}
			>
				<Box
					className="FloatingNav__nav"
					style={{ pointerEvents: "all" }}
					w="110px"
					mt="-10px"
					boxShadow="0px 0px 8px rgba(0, 0, 0, 0.15)"
					bg="white-01"
				>
					{children}
				</Box>
			</Box>
		</Box>
	);
};

export default FloatingNav;
