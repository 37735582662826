const CustomDateProp = ({ updateValue, value }) => {
  return (
    <div style={{ padding: '10px' }}>
      <span>Select Date/Time:</span>
      <input
        type="datetime-local"
        value={value}
        onChange={(e) => updateValue(e.target.value)}
      />
    </div>
  );
};

export default CustomDateProp;
