import React, { useEffect, useState } from "react";
import Masonry from "react-responsive-masonry";




const MasonryWrapper = ({ children }) => {

	const [columnsCount, setColumnsCount] = useState(3);
	const childrenArray = React.Children.toArray(children);

	useEffect(() => {

		const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setColumnsCount(3);
      } else if (window.innerWidth < 1024 && window.innerWidth >= 640) {
        setColumnsCount(2);
      } else {
        setColumnsCount(1);
      }
            
		};

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);

	}, []);

	return (
	
		<Masonry className="MasonryWrapper" columnsCount={columnsCount} gutter={32}>
		{childrenArray &&
			childrenArray.map((child, index) => {
				return (
					<div>
						{child}
					</div>
				);
			})
		}
		</Masonry>
		
	);
};

export default MasonryWrapper;
