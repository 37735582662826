import React from "react";
import { useState, useEffect } from "react";
import { usePlasmicContext } from "../../apps/abnormal/context/plasmic";
import {
	PlasmicComponent,
	PlasmicRootProvider,
} from "@plasmicapp/loader-nextjs";

import craft from "../../apps/abnormal/pages/api/craft";
import QueryPage from "@queries/QueryPage.graphql";
import { PLASMIC } from "../../apps/abnormal/plasmic-init";

export interface IProps {
	pageRoute?: string;
}

const RelatedResourcesBlade = ({ pageRoute }: IProps) => {
	const plasmicContext = usePlasmicContext();
	const [pageProps, setPageProps] = useState(null);
	const fetchCraftData = async (pageRoute) => {
		const result = await craft(QueryPage, {
			uri: pageRoute.slice(1),
			status: ["live", "disabled"],
		});
		if (!result["pageEntry"] && result["landingPageEntry"]) {
			result["pageEntry"] = result["landingPageEntry"];
		}
		return result;
	};

	//runtime in studio editor and previewing
	useEffect(() => {
		if (!plasmicContext.pageProps && pageRoute) {
			fetchCraftData(pageRoute).then((res) => {
				setPageProps(res);
			});
		}
	}, [pageRoute]);

	//during build time and local dev
	useEffect(() => {
		if (plasmicContext.pageProps) {
			setPageProps(plasmicContext.pageProps);
		}
	});
	if (!pageProps) return null;

	return (
		<PlasmicRootProvider loader={PLASMIC}>
			<PlasmicComponent
				component="RelatedContentSlider"
				projectId={process.env.NEXT_PUBLIC_PLASMIC_DESIGN_SYSTEM_PROJECT_ID}
				componentProps={{
					entries: pageProps.pageEntry.relatedContent,
				}}
			/>
		</PlasmicRootProvider>
	);
};

export default RelatedResourcesBlade;
