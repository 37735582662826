import { convertRegion, TO_ABBREVIATED } from "@abnormalLib/convertRegion";
import modifyUtms from "@abnormalLib/modifyUtms";
import { PlasmicCanvasContext } from "@plasmicapp/loader-nextjs";
import cookieCutter from "cookie-cutter";
import { useRouter } from "next/router";
import React, { ReactNode, useContext, useEffect, useState } from "react";

import { Box, CircularProgress, Flex } from "@chakra-ui/react";
import { IncomingWebhook } from "@slack/webhook";
import setFieldData from "final-form-set-field-data";
import { Form } from "react-final-form";
import { FormContext } from "../lib/formContext";

const EmbeddedFormPlasmic = (props: {
	children?: ReactNode;
	successMessage?: ReactNode;
	className?: string;
	marketoFormId?: string;
	formName?: string;
	showHidden?: boolean;
	darkBackground?: boolean;
  inputColorScheme?: string;
	setControlContextData?: (showHidden: any) => void;
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const {
		className,
		children,
		successMessage,
		marketoFormId,
		formName,
		showHidden,
		darkBackground,
    inputColorScheme,
		setControlContextData,
	} = props;
	const inEditor = useContext(PlasmicCanvasContext);

	setControlContextData?.({
		showHidden: showHidden,
	});

  const [urlParams, setUrlParams] = useState(null); /* UTM params from the utmParams cookie + query params */
  const [utmCampaign, setUtmCampaign] = useState({}); /* utm_campaign gets specific treatment, hold each version of it (Cookie, URL) here */
  const [savedFormValues, setSavedFormValues] = useState(null); /* Form values from the formValues cookie */
	const [countryValue, setCountryValue] = useState("");
	const [showState, setShowState] = useState(false);
  const [formCompleteTransforms, setFormCompleteTransforms] = useState({});

	const [formValues, setFormValues] = useState([]);

  const handleChange = (e) => {
		setFormValues({
			...formValues,
			...{ [e.target.name]: e.target?.checked ? "true" : e.target?.value },
		});
	};

	const updateCountryValue = (e) => {
		if (e == "United States") {
			setShowState(true);
		} else {
			setShowState(false);
		}
		setCountryValue(e);
	};

  function useRouterSafe() {
		try {
			return useRouter();
		} catch (err) {
			return undefined;
		}
  }
  
  const findDateFields = (field, items) => {
    var found = null;
    // Loop through all items
    itemLoop : for (const item of items){
      // If item has children, recursively call this function
      if (item.props && item.props.children && item.props.children.length > 1) {
        found = findDateFields(field, item.props.children);
      } else {
        // If fieldName matches, return
        if ( item.props?.fieldName === field) {
          found = item;
          break itemLoop;
        }
      }
    }
    return found;
}
	const router = useRouterSafe();
	if (typeof router === "undefined") return null;

  /* Get the router object, process query params */
	const basePath = router.asPath.split("?")[0].split("#")[0]
	let params = ""
	if (router.asPath.includes("?")) {
		const paramArray = router.asPath.split("?").join("&").split("&")
		paramArray.map((queryString, index) => {
			// remove hash from query string
			if (queryString.includes("#")) {
			paramArray[index] = queryString.replace(/#.*/, "")
			}
		})
		//remove basePath from param array
		paramArray.shift()
		//concatenate query params
		params = "?" + paramArray.join("&")
	}	

	useEffect(() => {
    /* Fetch utmParams cookie, URL parameters from the router query, and formValues cookie for use in form fields */
    const utmParams = cookieCutter.get("utmParams") ? JSON.parse(cookieCutter.get("utmParams")) : {};
    const routerItems = modifyUtms(router.query) || {};

    /* Set utm_campaign separately since it gets a different priority handling for the initial field value */
    setUtmCampaign({
      ...(utmParams?.utm_campaign && { "cookie": utmParams.utm_campaign }),
      ...(routerItems?.utm_campaign && { "url": routerItems.utm_campaign })
    });

    /* Set the urlParams object, omitting utm_campaign */
    setUrlParams({
      ...utmParams,
      ...routerItems,
    });
    /* Remove utm_campaign from the utmParams and routerItems objects */
    setUrlParams(currentParams => {
      const { utm_campaign, ...rest } = currentParams;
      return rest;
    });

    /* Process saved form values from the formValues cookie */
    if (cookieCutter.get("formValues")) {
      setSavedFormValues(JSON.parse(cookieCutter.get("formValues")));
      if (savedFormValues && savedFormValues["country"]) {
        setCountryValue(savedFormValues["country"]);
        if (savedFormValues["country"] === "United States") {
          setShowState(true);
        }
      }
    }

    // ZoomInfo FormComplete Hooks
    if (!window._zi_fc) {
      window._zi_fc = {}
    }
    window._zi_fc.onReady = (data) => {
      let customTransforms = {};
      // If there are inputs mapped in the form
      if (data?.inputs?.length > 0) {
        // Loop through the mapped inputs
        data.inputs.forEach((input) => {
          // If one of the inputs is a state field
          if (input.toLowerCase().includes("name='state'") || input.toLowerCase().includes("name=\"state\"")) {
            // Flag the form to do special FormComplete transforms for state
            customTransforms = {
              ...customTransforms,
              ...{ "state": true },
            };
          }
        });
        // Store custom transforms for FormComplete
        setFormCompleteTransforms(fcTransforms => ({ ...fcTransforms, ...customTransforms }));
      }
    }
  }, [router.query]);

  const onSubmit = async (values) => {
    setIsSubmitting(true);

    /* async sleep function (if needed)
    const sleep = async (num) => {
      return await new Promise((resolve) => setTimeout(resolve, num));
    };*/
    //await sleep(3000);

    // Loop through values and perform data transformations
    Object.keys(values).forEach((field) => {
      // if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug" || process.env.NODE_ENV === "development") {
      //   console.log("Field: ", field);
      // }
      if (field === "state" && formCompleteTransforms["state"] && !!values[field]) {
        //console.log("Located State Field: ",values[field]);
        values[field] = convertRegion(values[field], TO_ABBREVIATED);
      } else {
        // Parse through values and for any with fieldType containing datetime or Datetime, convert to ISO string
        var fieldData = findDateFields(field, children);
        if (fieldData) {
          if (fieldData.props?.fieldType?.toLowerCase().includes('datetime') && !!values[field]) {
            // console.log("Located DateTime Field: ",values[field]);
            values[field] = new Date(values[field]).toISOString();
          }
        }
      }
    });

    const postData = {} as {
      fields: { [key: string]: string };
      formId: string;
      marketoCookie: string;
      pageUrl: string;
    };
    postData["fields"] = values;
    postData["formId"] = marketoFormId;
    postData["marketoCookie"] = cookieCutter.get("_mkto_trk");
    postData["pageUrl"] = window.location.href;
    postData["fields"]["referralURL"] = window.location.href;

    if (window?.ga && process.env.NODE_ENV !== "development") {
      window.ga(function () {
        const tracker = window.ga.getAll()[0];
        const trackingId = tracker.get("trackingId");
        postData["fields"]["GATRACKID__c"] = trackingId;
        const clientId = tracker.get("clientId");
        postData["fields"]["GACLIENTID__c"] = clientId;
        const userId = tracker.get("userId");
        postData["fields"]["GAUSERID__c"] = userId;
      });
    }

    let result;

    // Debug Post Data Submission, Sent to Slack + Marketo
    if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug" || process.env.NODE_ENV === "development") {
      console.log("Post Data :", postData)
    }

    if (
      process.env.NEXT_PUBLIC_SLACK_WEBHOOK
    ) {
      const post = await fetch(
        `${process.env.NEXT_PUBLIC_CMS_URL}/api/submitForm`,
        {
          body: JSON.stringify(postData),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      result = await post.json();

      const webhookUrl = process.env.NEXT_PUBLIC_SLACK_WEBHOOK;

      const webhook = new IncomingWebhook(webhookUrl);
      delete webhook["axios"].defaults.headers["User-Agent"];
      webhook["axios"].defaults.headers["Content-Type"] =
        "application/x-www-form-urlencoded";
      
      const fieldsBlocks = [
      postData.fields["email"] ? {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*email*: ${postData.fields["email"]}`,
        },
      } : {
        type: "header",
        text: {
          type: "plain_text",
          text: `No Form Data`,
          emoji: true,
        },
      }
    ];

    Object.keys(postData.fields).forEach((field) => {
      if (!!postData.fields[field] && field !== "email" && field !== "referralURL") {
        fieldsBlocks.push({
          type: "section",
          text: {
            type: "mrkdwn",
            text: `*${field}*: ${postData.fields[field]}`,
          },
        });
      }
    });

      if (result.result[0]?.reasons === undefined) {
        webhook.send({
          attachments: [
            {
              color: "#00ff00",
              blocks: [
                {
                  type: "header",
                  text: {
                    type: "plain_text",
                    text: `Form Submission: ${formName}`,
                    emoji: true,
                  },
                },
                ...fieldsBlocks,
                {
                  type: "section",
                  text: {
                    type: "mrkdwn",
                    text: `*Result:* ${
                      result.result[0].status
                    }\n*Reason:*\n\`\`\`${JSON.stringify(
                      result.result[0]?.reasons
                    )}\`\`\``,
                  },
                },
                {
                  type: "section",
                  text: {
                    type: "mrkdwn",
                    text: `*Form Data:*\n\`\`\`${JSON.stringify(
                      postData
                    )}\`\`\``,
                  },
                },
                {
                  type: "section",
                  text: {
                    type: "mrkdwn",
                    text: `*Referral URL:* ${postData.fields["referralURL"]}\n\n<https://app-sj31.marketo.com/leadDatabase/loadLeadDetail?leadId=${result.result[0].id}|View Lead in Marketo>`,
                  },
                },
              ],
            },
          ],
        });
      } else {
        webhook.send({
          attachments: [
            {
              color: "#ff0000",
              blocks: [
                {
                  type: "header",
                  text: {
                    type: "plain_text",
                    text: "Website Form Submission Failed",
                    emoji: true,
                  },
                },
                {
                  type: "section",
                  text: {
                    type: "mrkdwn",
                    text: `*Users Notified: <@U05JA8KKC4T>\n*Form Name*: ${formName} \n*Referral URL:* ${window.location.href}`,
                  },
                },
                ,
                {
                  type: "section",
                  text: {
                    type: "mrkdwn",
                    text: `\`\`\`${JSON.stringify(result)}\`\`\`\``,
                  },
                },
              ],
            },
          ],
        });
      }
    } else {
      result = { success: true };
    }

		if (result.success === true) {
      /* Set expiration date for cookies */
      const expireDate = new Date();
      expireDate.setDate(expireDate.getDate() + 60);

      /* Map all visible fields to an object */
			const storedValues = {};
      children?.map((field) => {
        if (field.props.fieldType !== "hidden") {
          storedValues[field.props.fieldName] = values[field.props.fieldName];
        }
      });

      /* Merge the new stored values into the existing cookie values */
      const storedFormValues = {
        ...(cookieCutter.get("formValues") &&
          JSON.parse(cookieCutter.get("formValues"))),
        ...storedValues,
      };

      /* Save the updated cookie */
      cookieCutter.set("formValues", JSON.stringify(storedFormValues), {
        expires: expireDate,
      });

    // Debug Form Fill Data
    // if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug" || process.env.NODE_ENV === "development") {
    //   console.log("Form Fill Label :", formName)
    //   console.log("Form Fill Value :", window.location.href)
    // }

    if (window.dataLayer && process.env.NODE_ENV !== "development") {
      // Fire Google Form Fill Event
      window.dataLayer.push({
        event: "event",
        eventProps: {
          category: "Form",
          action: "Form Fill",
          label: formName,
          value: window.location.href,
        },
        formData: {
          email: postData.fields?.email,
          phone: postData.fields?.phone,
        }
      });
    }

    // Update state
    //console.log(result);
    setIsSubmitting(false);
    setIsSubmitted(true);

    // Update the url shallowly
    router.push(`${basePath + params + (params ? "&": "?" )}form=success`, undefined, { shallow: true });

    // Fire the callback fn
    } else {
      router.push(`${basePath + params + (params ? "&": "?" )}form=error`, undefined, { shallow: true });

      //TODO: display and handle errors
    }

    // TODO: alternatively, fire an event to Google Tag Manager test
  };

  return (
    <FormContext.Provider
      value={{
        urlParams: urlParams,
        utmCampaign: utmCampaign,
        savedFormValues: savedFormValues,
        countryValue: countryValue,
        formCompleteTransforms: formCompleteTransforms,
        showHidden: showHidden,
				darkBackground: darkBackground,
        inputColorScheme: inputColorScheme,
        handleChange: (e) => handleChange(e),
        changeState: (e) => {
          updateCountryValue(e);
        },
        formValues: formValues,
      }}
      width="100%"
    >
      <Form
        onSubmit={onSubmit}
        mutators={{ setFieldData }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <Flex
            as="form"
            className={className}
            flexDir="column"
            onSubmit={handleSubmit}
            width="100%"
            alignItems="flex-start"
            id={`form-${marketoFormId}`}
            data-form-id={marketoFormId}
          >
            {inEditor ? (
              <>
                <Box width="100%" mb="60px">
                  {children}
                </Box>
                <Box>{successMessage}</Box>
              </>
            ) : isSubmitting ? (
              <CircularProgress
                isIndeterminate
                color="#6863F2"
                trackColor="rgba(0,0,0,0)"
              />
            ) : isSubmitted ? (
              successMessage
            ) : (
              children
            )}
          </Flex>
        )}
      />
    </FormContext.Provider>
  );
};

export default EmbeddedFormPlasmic;
