import { Listbox } from "@headlessui/react";
import craft from "@pages/api/craft";
import QueryForm from "@queries/QueryForm.graphql";
import QueryForms from "@queries/QueryForms.graphql";
import React, { Children, useEffect, useState } from "react";

const FormField = ({ studioOps, componentProps, contextData }) => {
	const [forms, setForms] = useState([]);
	const { showHidden } = contextData;

	const fetchForm = async (formId) => {
		const response = await craft(QueryForm, {
			entryId: formId,
		});
		return response.marketoForms[0];
	};

	const [selected, setSelected] = useState({
		value: componentProps.marketoFormId ? componentProps.formId : null,
		label: componentProps.formName ? componentProps.formName : "Choose a Form",
	});

	const clearSlot = (slot, slotName) => {
		const hasChildren =
			typeof slot[0] !== "undefined" || !slot.props.isPropOrSlot;
		if (hasChildren) {
			Children.map(slot, () => {
				studioOps.removeFromSlotAt(0, slotName);
			});
		}
	};

	const fetchForms = async () => {
		if (forms.length < 1) {
			const response = await craft(QueryForms);
			const formArray = [];
			response.marketoForms.map((form) => {
				formArray.push({ label: form.name, value: form.id });
			});
			setForms(formArray);
		}
	};

	const handleChange = (event) => {
		if (event.value) {
			studioOps.updateProps({
				formId: event.value,
				formName: event.label,
			});
			setSelected(event);
			updateForm();
		}
	};

	const updateForm = async () => {
		const formData = await fetchForm(selected.value);

		studioOps.updateProps({
			marketoFormId: formData.remoteId,
		});

		const { children, successMessage } = componentProps;
		clearSlot(children, "children");
		clearSlot(successMessage, "successMessage");
		formData.formFields.map((field) => {
			studioOps.appendToSlot(
				{
					type: "component",
					name: "Form Fields",

					props: {
						label: field.label,
						fieldType: field.dataType,
						fieldName: field.remoteIdRest,
						required: field.required,
						options: {
							type: "json",
							value: field.dataType === "select" ? field.options : [],
						},
						defaultValue: field.defaultValue[0],
					},
				},
				"children"
			);
		});
		studioOps.appendToSlot(
			{
				type: "component",
				name: "Form Submit",
				props: {
					children: {
						type: "text",
						value: "Submit",
					},
				},
			},
			"children"
		);
		studioOps.appendToSlot(
			{
				type: "component",
        name: "Form Success",
				props: !!formData?.thankYouMessage && {
					Message: formData.thankYouMessage,
				},
			},
			"successMessage"
		);
	};

	const handleMouseEnter = (event) => {
		event.stopPropagation();
		if (event.currentTarget != event.target) return;
		event.target.style.background = "#ebebeb";
	};
	const handleMouseLeave = (event) => {
		event.stopPropagation();
		if (event.currentTarget != event.target) return;
		event.target.style.background = "inherit";
	};

	fetchForms();

	return (
		<div>
			{forms.length > 0 && (
				<Listbox
					value={selected.value}
					onChange={(event) => handleChange(event)}
				>
					<div
						style={{
							background: "#F3F3F2",
							width: "100%",
							borderRadius: "7px",
						}}
					>
						<Listbox.Button
							style={{
								width: "100%",
								padding: "4px",
								paddingRight: "10px",
								fontSize: "inherit",
								color: "#000",
								border: "none",
								borderRadius: "inherit",
								display: "flex",
								justifyContent: "space-between",
								background: "#F3F3F2",
								textAlign: "left",
							}}
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}
						>
							<div>{selected ? selected?.label : "Choose a form"}</div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={8}
								height={10}
								viewBox="0 0 24 24"
								strokeWidth="3"
								stroke="currentColor"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
								style={{
									verticalAlign: "middle",
									marginLeft: "10px",
									color: "##646464",
								}}
							>
								<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
								<polyline points="6 9 12 15 18 9"></polyline>
							</svg>
						</Listbox.Button>
					</div>
					<Listbox.Options
						style={{
							width: "100%",
							backgroundColor: "#fff",
							margin: 0,
							padding: "4px",
							//position: "absolute",
							//top: "33%",
							//left: "0%",
							maxHeight: "300px",
							overflowY: "scroll",
							zIndex: "999",
							cursor: "pointer",
							borderRadius: "4px",
							boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.15)",
						}}
					>
						{forms.map((form) => (
							<Listbox.Option
								key={form?.label}
								value={form}
								style={{
									display: "flex",
									marginBottom: "5px",
									padding: "4px",
								}}
								onMouseEnter={handleMouseEnter}
								onMouseLeave={handleMouseLeave}
							>
								{form?.label}
							</Listbox.Option>
						))}
					</Listbox.Options>
				</Listbox>
			)}
			<button onClick={() => updateForm()}>Reset Form</button>
		</div>
	);
};

export default FormField;
